import {Inertia} from '@inertiajs/inertia';
import {mdiChevronDown, mdiChevronUp, mdiTrashCanOutline} from '@mdi/js';
import {Icon} from '@mdi/react';
import {ConfirmationModal, Tooltip} from '@webfox/webfox-ui';
import cls from 'classnames';
import {ImageModal} from 'Pages/InventoryItems/Partials/MediaSection/ImageModal';
import {ImageWithOverlay} from 'Pages/InventoryItems/Partials/MediaSection/ImageWithOverlay';
import React, {useEffect, useState} from 'react';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import ImageDragItem from 'Support/DragItems/ImageDragItem';
import route from 'ziggy-js';

function updateIndex(image, index) {
  const newIndex = index - 1;
  console.log(newIndex);
  Inertia.put(route('items.media.update-order', {media: image.id, archived_inventory_item: image.model_id, newIndex}));
}

export const ImageBlock = ({image, editable}) => {
  const [enlargeImage, setEnlargeImage] = useState();
  const [deletingImage, setDeletingImage] = useState(false);

  const [{isDragging}, drag, preview] = useDrag(
    {
      type: ImageDragItem.type,
      item: ImageDragItem(image),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        item: monitor.getItem(),
        type: monitor.getItemType(),
      }),
      canDrag: () => !editable,
    },
    [image],
  );

  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, [preview]);

  return (
    <div className="relative w-full">
      {!editable && (
        <Tooltip content="Delete Image">
          <button
            onClick={() => setDeletingImage(true)}
            className="absolute right-1 top-5 z-10 flex justify-end rounded bg-gray bg-opacity-75 p-1 text-red"
          >
            <Icon path={mdiTrashCanOutline} className="w-4"/>
          </button>
        </Tooltip>
      )}
      <button
        onClick={() => {
          updateIndex(image, image.order_column - 1);
        }}
        className="h-4 w-full bg-gray-400/20 rounded-lg flex justify-center lg:hidden">
        <Icon path={mdiChevronUp} className="w-4 h-4 text-gray-400"/>
      </button>
      <div ref={drag} className={cls(isDragging && 'opacity-30', 'group relative flex items-center rounded-lg')}>
        <ImageWithOverlay image={image} setEnlargeImage={setEnlargeImage}/>
      </div>
      <button
        onClick={() => {
          updateIndex(image, image.order_column + 1);
        }}
        className="h-4 w-full bg-gray-400/20 rounded-lg flex justify-center lg:hidden">
        <Icon path={mdiChevronDown} className="w-4 h-4 text-gray-400"/>
      </button>
      <ImageModal image={enlargeImage} onClose={() => setEnlargeImage(null)} className={!!enlargeImage ? '' : 'scale-0 opacity-0'}/>
      <ConfirmationModal
        onCancel={() => setDeletingImage(false)}
        onConfirm={() => {
          Inertia.delete(route('media.remove-image', {media: image.id}), {
            preserveState: true,
            preserveScroll: true,
            onStart: () => setDeletingImage(true),
            onFinish: () => setDeletingImage(false),
          });
        }}
        open={deletingImage}
        title="Delete Image"
        subTitle="Are you sure you want to delete this image? You will not be able to recover it."
        confirmText="Delete"
      />
    </div>
  );
};
