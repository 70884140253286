import { Inertia } from '@inertiajs/inertia';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import { AddItemImageField } from 'Components/InventoryItems/AddItemImageField';
import { ImageBlock } from 'Pages/InventoryItems/Partials/MediaSection/ImageBlock';
import { ImageInbetween } from 'Pages/InventoryItems/Partials/MediaSection/ImageInBetween';
import React, { Fragment, useState } from 'react';
import route from 'ziggy-js';

const MediaSection = ({ itemId, media, editable }) => {
  const [uploading, setUploading] = useState(false);
  const onImageChange = (blob, url, { reset }) => {
    if (blob === null) return;
    const formData = new FormData();
    formData.append('image', blob, 'image.webp');
    Inertia.post(route('items.add-image', itemId), formData, {
      onStart: () => setUploading(true),
      onFinish: () => {
        setUploading(false);
        reset();
      },
      preserveState: true,
      preserveScroll: true,
    });
  };

  return (
    <div className="flex lg:flex-wrap flex-col lg:flex-row items-start justify-center lg:justify-start">
      <div className={cls('h-34 relative w-full lg:hidden', uploading && 'pointer-events-none')}>
        {editable && <AddItemImageField onChange={onImageChange} />}
        {uploading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Icon path={mdiLoading} className="w-10 animate-spin" />
          </div>
        )}
      </div>
      {media?.map((image, index) => {
        return (
          <Fragment key={image.id}>
            <ImageInbetween key={`inbetween-${index}`} index={index} />
            <ImageBlock key={image.id} image={image} editable={!editable} />
            {index === media.length - 1 && <ImageInbetween key={`inbetween-${index + 1}`} index={index + 1} />}
          </Fragment>
        );
      })}
      <div className={cls('relative hidden h-40 w-40 lg:block', uploading && 'pointer-events-none')}>
        {editable && <AddItemImageField onChange={onImageChange} />}
        {uploading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Icon path={mdiLoading} className="w-10 animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaSection;
